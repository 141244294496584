























import { Component, Vue, Prop } from 'vue-property-decorator';
import {listenCursorInputOnTable} from "@/components/mixins/TableCursorHandler";
import AddSection from "@/components/Table/AddSection.vue";

@Component({
  components: {AddSection}
})
export default class extends Vue {
  @Prop({required: true}) private data!:any[];
  @Prop({default: true}) private allowEmpty!:boolean;
  @Prop({default: null}) private rowKey!:string|((row) => string)|null;
  @Prop({default: false}) private withMargin!:boolean;
  @Prop({default: false}) private withMarginBottom!:boolean;
  @Prop({default: false}) private fixed!:boolean;
  @Prop({default: ''}) private tableClass!:string;
  @Prop({default: true}) private enableArrowKeyMove!:boolean;
  @Prop({default: false}) private disabled!:boolean;
  @Prop({required: false}) private onDeleting?:(data: any) => Promise<boolean>;

  private async deleteRow(index: number) {
    if (this.disabled) return;
    if(!this.allowEmpty && this.data.length === 1) return;
    if (this.onDeleting && !await this.onDeleting(this.data[index])) return;

    this.data.splice(index, 1);
  }

  private addRow() {
    if (this.disabled) return;
    this.$emit('add');
  }

  private mounted() {
    if (this.enableArrowKeyMove) {
      listenCursorInputOnTable(this.$el);
    }
  }
}



































import { Component, Vue, Prop } from 'vue-property-decorator';
import {ImportDataType, ExportTemplateType} from "@/repositories/spec/company/spec-repository";
import OnFileSelectedHandler from "@/components/Form/OnFileSelectedHandler";
import IFile, {IFileDefaultValue, IFileUpload} from "@/contracts/i-file";
import InfomartImportModal from "@/views/spec/companies/components/InfomartImportModal.vue";
import {FoodTypeForSpec} from "@/entities/specs/spec-entity";
@Component({
  components: {InfomartImportModal}
})
export default class extends Vue {
  @Prop({type: Boolean, default: false}) divide!: boolean;
  @Prop({default: false}) disabled!: boolean;

  private readonly ImportDataType = ImportDataType;
  private infomartVisible = false;

  private async onFileSelected(evt, type:ImportDataType) {
    const handler = new OnFileSelectedHandler({ maxFileSize: 50 * 1024 * 1024, });
    handler.onFileSelected(evt.target.files).then((res:any) => {
      const filename = evt.target.files[0].name;
      evt.target.value = ''; // clear
      const file = { filename: filename, body: res.target.result } as IFileUpload;
      this.$emit('onFileSelected', type, file);
    });
  }
  private async onDirectorySelected(evt, type:ImportDataType) {
    Promise.all(
      Array.from(evt.target.files).map(async (file:File|any) => {
        const handler = new OnFileSelectedHandler({ maxFileSize: 50 * 1024 * 1024, });
        const res = await handler.validateAndRead(file);
        return { filename: file.name, body: res.target.result } as IFileUpload;
      })
    ).then((results:IFileUpload[]|any ) => {
      evt.target.value = ''; // clear
      this.$emit('onDirectorySelected', type, results);
    });
  }

  private submitInfomart(data: {file: IFile; foodType: FoodTypeForSpec} ) {
    this.$emit('onFileSelected', ImportDataType.INFOMART, data.file, { foodType: data.foodType });
  }

}






















































































































import { Vue, Component, Prop} from 'vue-property-decorator';
import AttachmentImage from "./components/AttachmentImage.vue";
import AdditiveRepository, {TAdditiveSynonymListItem} from "@/repositories/master/additive-repository";
import SpecIngredientEntity, {HideableFields} from "@/entities/specs/spec-ingredient-entity";
import {CarryoverReasonDict} from "@/entities/carryover-entity";

import {getAllergenNameById} from '@/entities/allergy-entity';
import ShowBase from "@/views/spec/companies/components/spec-components/show/ShowBase";
import {SpecDocumentAttachmentPreparedType} from "@/entities/specs/spec-attachment-entity";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import {i18n} from "@/bootstraps/locale";
import StringUtil from "@/utils/string-utils";
import {makeObserver} from "@/utils/intersection-observer-util";

@Component({
  components: {
    AttachmentImage,
    CustomValueShowSection,
  }
})
export default class extends ShowBase {
  private readonly getAllergenNameById = getAllergenNameById;
  private readonly HideableFields = HideableFields;
  private readonly CarryoverReasonDict = CarryoverReasonDict;
  private additiveSynonymList:TAdditiveSynonymListItem[] = [];
  private readonly SpecDocumentAttachmentPreparedType = SpecDocumentAttachmentPreparedType;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;

  private showAmountInTotal = false;

  private initialized = false;
  private maxIngredientDepth = 0;

  private observer: IntersectionObserver = null as any;

  private async created() {
    this.maxIngredientDepth = this.spec.ingredients.length > 0 ?
      Math.max(...this.spec.ingredients.map(ing => this.spec.getIngredientsDepth(ing))) + 1 :
      1;

    await Promise.any([
      new AdditiveRepository().listForSpec().then(list => {
        this.additiveSynonymList = list;
      }),
    ])

    this.showAmountInTotal = this.spec.isAmountRatioInTotal;
    this.initialized = true;

    this.spec.setIngredientsChildrenWithMutating(); // childrenを作る
  }
  private mounted() {
    this.observer = makeObserver('stuck');
    this.observer.observe(this.$refs.theadRef as Element);
  }
  private beforeDestroy() {
    this.observer.unobserve(this.$refs.theadRef as Element);
  }

  private get isDev():boolean {
    return !!this.$route.query.dev;
  }

  public getHideableField(ing: SpecIngredientEntity, field:string): string {
    if(ing.isFieldHidden(field)) {
      return i18n.t('(非公開)');
    }
    if (field === HideableFields.Amount) {
      const amount = ing.getAmount(this.showAmountInTotal);
      if (amount === null) {
        return i18n.t('(非公開)');
      }
      return amount.toFixed(3) + "%";
    }
    return ing[StringUtil.toCamelCase(field)];
  }
}
